exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-blog-js": () => import("./../../../src/pages/index-blog.js" /* webpackChunkName: "component---src-pages-index-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-ai-js": () => import("./../../../src/pages/services/ai.js" /* webpackChunkName: "component---src-pages-services-ai-js" */),
  "component---src-pages-services-api-development-js": () => import("./../../../src/pages/services/api-development.js" /* webpackChunkName: "component---src-pages-services-api-development-js" */),
  "component---src-pages-services-app-development-js": () => import("./../../../src/pages/services/app-development.js" /* webpackChunkName: "component---src-pages-services-app-development-js" */),
  "component---src-pages-services-cloud-native-js": () => import("./../../../src/pages/services/cloud-native.js" /* webpackChunkName: "component---src-pages-services-cloud-native-js" */),
  "component---src-pages-services-data-analytics-js": () => import("./../../../src/pages/services/data-analytics.js" /* webpackChunkName: "component---src-pages-services-data-analytics-js" */),
  "component---src-pages-services-data-consulting-js": () => import("./../../../src/pages/services/data-consulting.js" /* webpackChunkName: "component---src-pages-services-data-consulting-js" */),
  "component---src-pages-services-data-engineering-js": () => import("./../../../src/pages/services/data-engineering.js" /* webpackChunkName: "component---src-pages-services-data-engineering-js" */),
  "component---src-pages-services-database-js": () => import("./../../../src/pages/services/database.js" /* webpackChunkName: "component---src-pages-services-database-js" */),
  "component---src-pages-services-ecommerce-js": () => import("./../../../src/pages/services/ecommerce.js" /* webpackChunkName: "component---src-pages-services-ecommerce-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-technologies-js": () => import("./../../../src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-list-blog-list-js": () => import("./../../../src/templates/blog-list/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-blog-list-js" */),
  "component---src-templates-blog-template-blog-template-js": () => import("./../../../src/templates/blog-template/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-blog-template-js" */)
}

