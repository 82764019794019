import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LdJMY8aAAAAAIWdBop76fza5cRPTj72nQ0vDTE-">
            {element}
        </GoogleReCaptchaProvider>
    )
};



